const template = require('./AccountBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ServerConfig = require('../../ServerConfig');

const EDIT_URL = '/modifier-mon-compte';

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'AccountBlock.',
            keys: [
                'title',
                'kitDescription',
                'editText',
                'downloadKitText',
            ],
        }),
    ],
    computed: {
        blockLink() {
            const {editText: text} = this;
            return {
                href: EDIT_URL,
                text,
            };
        },
        comKitFilePath() {
            return ServerConfig.config.fileUrl + '/raw/kitcom';
        },
    },
    template: template(),
};
