const _ = require('lodash');
const Account = require('../authentication/Account');
const VueApplicationPage = require('../pages/VueApplicationPage');
const ProspectingMapPageMixin = require('./components/ProspectingMapPageMixin');

module.exports = class MyProspectingMapPage extends VueApplicationPage {
    constructor() {
        super({
            name: 'myProspectingMapPage',
            title: 'Ma carte de prospection',
            isRegistrationRequired: true,
        });
    }

    open(options) {
        super.open(_.defaults({subscriptionFormVisible: false}, options));
    }

    getVueComponent() {
        // @vue/component
        return {
            mixins: [
                ProspectingMapPageMixin,
            ],
            computed: {
                agency() {
                    return Account.getAuthenticatedAccount();
                },
                canDisplayProspectingMap() {
                    return this.agency.canSeeProspectingMap;
                },
            },
        };
    }

    getUrlPattern() {
        return '/ma-carte-de-prospection';
    }
};
