const template = require('./RealEstateAdSuggestions.jade');

// @vue/component
module.exports = {
    props: {
        title: {
            type: String,
            required: true,
        },
        titleDescription: {
            type: String,
            required: true,
        },
        config: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            collapsedBlock: true,
        };
    },
    methods: {
        iconModifiers(name) {
            return {
                [name]: true,
            };
        },
    },
    template: template(),
};
