const template = require('./AccountStatsBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const DateFormatter = require('../../../common/formatters/DateFormatter');
const MomentValidator = require('../../vue/validators/Moment');
const AccountStats = require('../../stats/components/AccountStats');
const RealEstateAdSummary = require('../../agency/components/RealEstateAdSummary');

// @vue/component
module.exports = {
    constants: {
        SEE_ALL_LINK: '/mes-statistiques',
    },
    components: {
        AccountStats,
        RealEstateAdSummary,
    },
    mixins: [
        i18nMixin({
            prefix: 'AccountStatsBlock.',
            keys: [
                'title',
                {
                    seeAll: 'seeAllText',
                    seeAllSmall: 'seeAllSmallText',
                },
            ],
        }),
    ],
    props: {
        accountId: {
            type: String,
            required: true,
        },
        statsMode: {
            type: String,
            default: null,
        },
        startDate: {
            type: Object,
            required: true,
            validator: MomentValidator,
        },
        endDate: {
            type: Object,
            required: true,
            validator: MomentValidator,
        },
        adSummaryOptions: {
            type: Object,
            required: true,
        },
    },
    computed: {
        subtitle() {
            const {t, startDate, endDate} = this;
            return t('duration', {
                fromDate: DateFormatter.humanizeDay(startDate),
                toDate: DateFormatter.humanizeDay(endDate),
            });
        },
        link() {
            const {SEE_ALL_LINK, seeAllSmallText} = this;
            return {
                href: SEE_ALL_LINK,
                text: seeAllSmallText,
            };
        },
    },
    template: template(),
};
