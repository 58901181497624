const Pages = require('../pages/Pages');
const PagesFactories = require('../pages/PagesFactories');
const {addRoute, addPage} = require('../pages/PageManager');
const DetailedSheetPage = require('../pages/DetailedSheetPage');
const Urls = require('../Urls');
const _ = require('lodash');
const MyRealEstateAdsPageFactory = require('../search/pageFactories/MyRealEstateAdsPageFactory');
const UserRealEstateAdsPageFactory = require('../search/pageFactories/UserRealEstateAdsPageFactory');
const ApplicationRoutesProCommon = require('./ApplicationRoutesProCommon');
const headerTemplate = require('../header/applicationHeaderPro.jade');
const {DISPLAY_MODE_LIST} = require('../search/constants');

module.exports = configureRoutes;

function configureRoutes(savedOptions) {
    ApplicationRoutesProCommon({
        savedOptions,
        isMobile: false,
        headerTemplate,
    });

    const myRealEstateAdsPageFactory = new MyRealEstateAdsPageFactory({
        moreFiltersVisible: true,
        defaultListMode: DISPLAY_MODE_LIST,
    });
    addPage(myRealEstateAdsPageFactory);

    const userRealEstateAdsPageFactory = new UserRealEstateAdsPageFactory({
        moreFiltersVisible: true,
        defaultListMode: DISPLAY_MODE_LIST,
    });
    addPage(userRealEstateAdsPageFactory);

    const detailedSheetPage = new DetailedSheetPage({
        pageRedirectionOnLogout: '/',
        requiresRegistration: true,
        createSearchNavigationIfMissing: false,
        defaultListMode: DISPLAY_MODE_LIST,
        pageContext: 'pro',
        partnerServicesEnabled: false,
    });
    addRoute(Urls.detailedSheet.pattern, detailedSheetPage);

    _.extend(Pages, {
        detailedSheet: detailedSheetPage,
    });

    _.extend(PagesFactories, {
        userRealEstateAdsPageFactory,
        myRealEstateAdsPageFactory,
    });
}
