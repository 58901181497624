const _ = require('lodash');
const AbstractAccountStatsPage = require('./AbstractAccountStatsPage');
const Account = require('../authentication/Account');

module.exports = class MyAccountStatsPage extends AbstractAccountStatsPage {
    constructor(options) {
        options = _.defaults(options || {}, {
            name: 'myAccountStats',
            title: 'Mes statistiques',
            isRegistrationRequired: true,
            showOwnedAccountSelection: false,
        });

        super(options);
    }

    _loadData(options, cb) {
        this.accountId = Account.getAuthenticatedAccountId();
        cb(null, _.extend(options, {
            account: Account.getAuthenticatedAccount(),
        }));
    }

    getUrlPattern() {
        return '/mes-statistiques';
    }

    getUrl() {
        return '/mes-statistiques';
    }
};
