const _ = require('lodash');
const async = require('async');

const ApplicationPage = require('../ApplicationPage');
const Account = require('../../authentication/Account');
const ArticleHelper = require('../../blog/article/ArticleHelper');
const CancelableCallback = require('../../utils/CancelableCallback');
const LoadUserAdsHelper = require('../../search/LoadUserAdsHelper');
const BlogHelper = require('../../blog/BlogHelper');
const adsManager = require('../../search/adsManager');
const PagesFactories = require('../PagesFactories');
const SearchUrlPrefixes = require('../../../common/SearchUrlPrefixes');
const Urls = require('../../Urls');
const AdAnalyzeConf = require('../../AdAnalyzeConf');
const StatsHelper = require('../../stats/StatsHelper');

const DashboardProView = require('../../views/pro/DashboardProView');

module.exports = class DashboardProPage extends ApplicationPage {
    constructor() {
        super({
            name: 'dashboardPro',
            title: 'Mon tableau de bord',
            isRegistrationRequired: true,
            bodyClass: 'dashboard',
        });
        this._dashboardProView = new DashboardProView();
    }

    _loadData(options, cb) {
        const mainBlogTag = 'nos-offres-partenaires';
        const account = Account.getAuthenticatedAccount();
        const blogTag = BlogHelper.getProBlogTagWithFilters(mainBlogTag, {accountType: account.accountType});
        async.auto({
            articlesData: cb => {
                this._getArticlesList(blogTag || mainBlogTag, (err, articlesList) => {
                    cb(err, {
                        articlesList,
                    });
                });
            },
            searchResults: callback => {
                const loadOptions =
                    PagesFactories.myRealEstateAdsPageFactory.getRealEstateAdsLoadingOptionsWithCountBySearchCriterion(callback);
                adsManager.loadResults(loadOptions);
            },
        }, (err, {articlesData, searchResults}) => {
            cb(err, _.extend(options, articlesData, {
                account,
                blogTag,
            }, {
                improvableAdsConf: this.getImprovableAdsConf(searchResults),
                adDiagnosticConf: this.getAdDiagnosticConf(searchResults),
            }));
        });
    }

    getImprovableAdsConf(searchResults) {
        return this.getConfFromCounts(searchResults, AdAnalyzeConf.getConf(), 'improvableConf');
    }

    getAdDiagnosticConf(searchResults) {
        return this.getConfFromCounts(searchResults, AdAnalyzeConf.getConf(), 'diagnosticConf');
    }

    getConfFromCounts(searchResults, conf, type) {
        const confByType = conf[type];
        const filterCounts = this.getFilterCounts(searchResults, _.keys(confByType));
        return _.mapValues(filterCounts, (count, key) => (_.assignIn({
            count,
            searchUrl: Urls.search.makeUrl({[key]: true, filterType: ['buy', 'rent']}, null, SearchUrlPrefixes.myRealEstateAds),
        }, confByType[key])));
    }

    getFilterCounts(searchResults, values) {
        return _.pickBy(_.get(searchResults, 'filterCounts'), (count, key) => _.includes(values, key) && count && count > 0);
    }

    open(options) {
        options = _.extend(options, StatsHelper.getDefaultPeriod());
        super.open(options);
        this._updateUserAdsCount(options);
    }

    _updateUserAdsCount(options) {
        this._pendingCallback = CancelableCallback(options => {
            this._cancelUserAdsCountRequest();
            this._dashboardProView.showUserAdsCount(options);
        });
        LoadUserAdsHelper.loadUserAds({
            author: options.account,
            origin: 'dashboard',
            onlyOwnData: false,
        }, this._pendingCallback);
    }

    _cancelUserAdsCountRequest() {
        if (this._pendingCallback) {
            this._pendingCallback.cancel();
            this._pendingCallback = null;
        }
    }

    close(options) {
        this._cancelUserAdsCountRequest();
        super.close(options);
    }

    getUrlPattern() {
        return '/mon-tableau-de-bord';
    }

    getMiddleViews() {
        return this._dashboardProView;
    }

    _getArticlesList(tag, cb) {
        const params = {
            size: 3,
            tags: tag,
        };
        return ArticleHelper.getArticles({
            params,
            callback: cb,
        });
    }
};
