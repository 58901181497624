const _ = require('lodash');
const {i18n: {translate}} = require('fack');
const Account = require('../authentication/Account');
const AccountCompletionView = require('../authentication/AccountCompletionView');
const CancelableCallback = require('../utils/CancelableCallback');
const HeaderView = require('./HeaderView');
const ModalMessage = require('../utils/ModalMessage');

module.exports = class HeaderProView extends HeaderView {
    constructor(options) {
        super(options);
        this._accountCompletionView = new AccountCompletionView();
        _.extend(this.vueData, {
            selectedHeaderItem: null,
            registeredPartnerOffersLink: null,
            firstOwnedAccountNamespaceOrAccountId: null,
        });
    }

    onPageChanged(page) {
        super.onPageChanged(page);
        const selectedHeaderItem = page.getSelectedHeaderItem();
        this.updateActiveLinkPro(selectedHeaderItem);
        this.vueData.selectedHeaderItem = selectedHeaderItem;
    }

    handleAccountChange() {
        const {vueData} = this;
        vueData.registeredPartnerOffersLink = this.getRegisteredPartnerOffersLink();
        const showOwnedAccountLink = this.showOwnedAccountLink();
        if (showOwnedAccountLink) {
            const ownedAccount = _.first(Account.getAuthenticatedAccountOwnedAccounts());
            vueData.firstOwnedAccountNamespaceOrAccountId =
                ownedAccount && (ownedAccount.namespace || ownedAccount._id);
        }

        super.handleAccountChange();
    }

    _hasToShowNoContractPopup() {
        return !Account.isNetwork()
            && !Account.hasImport()
            && !Account.hasContract();
    }

    _showPopupsIfNeeded() {
        if (Account.isRegistered()) {
            if (this._hasToShowNoContractPopup()) {
                const context = Account.isDeveloper() ? 'developer' : '';
                ModalMessage.show({
                    title: translate('noContractModal.title'),
                    confirmText: translate('noContractModal.confirm'),
                    message: translate('noContractModal.pitch', {context: context}),
                    hideCallback: () => {
                        this._showAccountCompletion();
                    },
                });
            } else {
                this._showAccountCompletion();
            }
        }
    }

    _showAccountCompletion() {
        const accountId = Account.getAuthenticatedAccountId();
        this._pendingCallback = CancelableCallback((err, result) => {
            this._cancelHasPasswordRequest();
            if (err) {
                console.error('could not get password info for account ', accountId);
            } else if (!result.success) {
                this._accountCompletionView.show({
                    account: Account.getAuthenticatedAccount(),
                });
            }
        });
        Account.hasPassword({id: accountId}, this._pendingCallback);
    }
};
