const {i18n: {translate}} = require('fack');
const $ = require('jquery');
const _ = require('lodash');
const accountCreationTemplate = require('../templates/account/proAccountCreationView.jade');
const accountCreationSuccessModalTemplate = require('../templates/proAccountCreationSuccessModal.jade');
const errorMessageModalTemplate = require('../templates/errorMessageModal.jade');
const CreateAccountForm = require('./CreateAccountForm');
const VueView = require('../vue/VueView');
const Account = require('../authentication/Account');

module.exports = class ProAccountCreationView extends VueView {
    constructor() {
        super({template: accountCreationTemplate});
    }

    show() {
        const proAccountCreationView = this;
        super.show({
            Account,
            showPasswordSection: true,
            showEmailAsLoginSection: true,
            showCompanySection: true,
            showProContactSection: true,
            showOtherContactsSection: true,
            showApprovalQuestionsSection: true,
            approvalQuestions: ['wantsToReceiveDirectOffersAndNewsLetter'],
        }, {
            mixins: [
                require('../fields/vue/validationMixin')({
                    initBootstrapValidator: false, // already initialised later in AccountForm. TODO: remove from AccountForm
                }),
            ],
            mounted() {
                //using nextTick to wait for all child components to be rendered (https://vuejs.org/v2/api/#mounted)
                this.$nextTick(_.bind(proAccountCreationView.initAccountForm, proAccountCreationView));
            },
            data: {
                address: {},
            },
            computed: {
                headerTitle() {
                    return translate('accountCreationView.headerTitle');
                },
            },
        });
    }

    initAccountForm() {
        this.accountForm = new CreateAccountForm($(this.vm.$el));
        this.accountForm.on('accountCreated', () => {
            const $successModal = this.renderTemplate(accountCreationSuccessModalTemplate);
            $successModal.modal('show');
        });

        //ugly hack
        this.accountForm.showError = function (translatedMessage) {
            const $body = $('body');
            const $modal = $(errorMessageModalTemplate({
                errorTitle: translate('accountCreationServerErrorTitle'),
                errorMessage: translatedMessage,
            }));
            $body.append($modal);
            $modal.modal('show');
            $modal.on('hidden.bs.modal', function () {
                $modal.remove();
                $body.removeClass('modal-open'); //this class should be removed on modal hide, but it is not
            });
        };
    }

    hide(options, cb) {
        this.accountForm.clear();
        super.hide(options, cb);
    }
};
