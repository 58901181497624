const _ = require('lodash');
const RealEstateAdSuggestions = require('./RealEstateAdSuggestions');
const template = require('./RealEstateAdsPerfSuggestionsBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const NumberFormatter = require('../../../common/NumberFormatter');

// @vue/component
module.exports = {
    components: {
        RealEstateAdSuggestions,
    },
    mixins: [
        i18nMixin({
            prefix: 'RealEstateAdsPerfSuggestionsBlock.',
            keys: [
                'title',
                'titleDescription',
                'description',
            ],
        }),
    ],
    props: {
        perfConfig: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            collapsedBlock: true,
        };
    },
    computed: {
        config() {
            const config = _.cloneDeep(this.perfConfig);
            _.entries(config).forEach(([key, value]) => {
                value.searchUrlTitle = this.translate(value, key, 'title');
                value.blogUrlTitle = this.translate(value, key, 'linkToArticleTitle');
            });
            return config;
        },
    },
    methods: {
        translate(item, name, translationKey) {
            return this.t(`adsThatCanBeImproved.${name}.${translationKey}`, {
                count: item.count,
                formattedCount: NumberFormatter.formatNumber(item.count),
            });
        },
    },
    template: template(),
};
