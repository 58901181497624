const template = require('./AdsNotificationBoostsHistoryBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const RealEstateAdNotificationBoostHistory = require('../../notificationBoost/components/RealEstateAdNotificationBoostHistory');
const Account = require('../../authentication/Account');

// @vue/component
module.exports = {
    components: {
        RealEstateAdNotificationBoostHistory,
    },
    constants: {
        ADS_NOTIFICATION_BOOST_HISTORY_PAGE_LINK: '/mes-emailings-plus',
    },
    mixins: [
        i18nMixin({
            prefix: 'AdsNotificationBoostsHistoryBlock.',
            keys: [
                'title',
                'errorMessage',
                'descriptionNotUsedYet',
                'seeAllButtonText',
            ],
        }),
    ],
    props: {
        accountId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            collapsedBlock: false,
            isLoading: true,
            hasError: false,
            boostsHistory: [],
            numberOfRemainingBoosts: 0,
        };
    },
    computed: {
        hasHistory() {
            return this.boostsHistory.length > 0;
        },
        descriptionHtml() {
            const {numberOfRemainingBoosts} = this;
            const context = {count: numberOfRemainingBoosts};
            const translationKey = numberOfRemainingBoosts > 0 ? 'numberOfRemainingBoostsText' : 'descriptionOutOfCredit';
            return this.t(translationKey, context);
        },
    },
    mounted() {
        Account.authAjax({
            url: '/api/ads-notification-boosts-history',
            data: {
                accountId: this.accountId,
                limit: 3,
                transactionTypes: ['rent', 'buy'],
            },
            disableErrorPage: true,
            callback: (err, result) => {
                this.isLoading = false;
                if (err || !result.success) {
                    this.hasError = true;
                } else {
                    const {data: {boostsHistory, numberOfRemainingAdNotificationBoosts}} = result;
                    this.boostsHistory = boostsHistory;
                    this.numberOfRemainingBoosts = numberOfRemainingAdNotificationBoosts;
                }
            },
        });
    },
    template: template(),
};
