const Account = require('../authentication/Account');
const VueApplicationPage = require('../pages/VueApplicationPage');
const AdsNotificationBoostsHistory = require('./components/AdsNotificationBoostsHistory');
const template = require('./MyAdsNotificationBoostsHistoryPage.jade');

module.exports = class MyAdsNotificationBoostsHistoryPage extends VueApplicationPage {
    constructor() {
        super({
            name: 'myAdsNotificationBoostsHistory',
            title: 'Mes emailings+',
            isRegistrationRequired: true,
        });
    }

    isPageAccessAllowed() {
        return Account.canDisplayAdsNotificationBoostsHistory();
    }

    getVueComponent() {
        // @vue/component
        return {
            components: {
                AdsNotificationBoostsHistory,
            },
            computed: {
                accountId() {
                    return Account.getAuthenticatedAccountId();
                },
            },
            template: template(),
        };
    }

    getUrlPattern() {
        return '/mes-emailings-plus';
    }
};
