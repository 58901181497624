const $ = require('jquery');
const _ = require('lodash');

const View = require('../views/View');
const FormUtils = require('../fields/FormUtils');
const Account = require('./Account');
const FormLoadingState = require('../FormLoadingState');
const template = require('../templates/account/accountCompletionTemplate.jade');
const ServerConfig = require('../ServerConfig');

module.exports = class AccountCompletionView extends View {
    constructor() {
        super({
            template,
            $container: $('body'),
        });
    }

    show(options) {
        const {accountUrl} = ServerConfig.config;
        this.options = options;
        super.show(_.extend({accountUrl}, options));
        this.$form = this.$element.find('form');
        this.$validateButton = this.$form.find('.tryAccountCompletion');
        this._initDom(options);
        this._initFormValidator();
    }

    _initFormValidator() {
        FormUtils.init({
            $form: this.$form,
            submit: _.bind(this._submitForm, this),
        });
    }

    _initDom(options) {
        this.$element.modal({keyboard: true});
        this._fillDom(options.account);
        this.$element.on('hidden.bs.modal', () => {
            this.hide();
        });
    }

    _fillDom(account) {
        const passwordCred = _.find(account.credentials, function (cred) {
            return cred.type == 'password';
        });
        this.credentials = passwordCred;
        if (passwordCred) {
            this.$element.find('.credentialIdInput').val(passwordCred.id);
        }
    }

    _submitForm() {
        const loadingState = new FormLoadingState({
            $form: this.$form,
            $button: this.$validateButton,
        });
        loadingState.show();

        const credentialsData = _.extend(
            {type: 'password'},
            _.omit(FormUtils.readValues(this.$form), 'password-confirmation')
        );
        credentialsData.id = credentialsData.id.trim();

        const data = {
            credentials: [credentialsData],
            id: this.options.account.id,
        };

        Account.update(data, err => {
            if (err) {
                console.error('an error occurred while updating account');
            } else {
                this.$element.modal('hide');
                Account.sendProAccountCompletedEmail(err => {
                    if (err) {
                        console.error('Send pro account update email error: ', err);
                    }
                });
            }
        });
    }
};
