const _ = require('lodash');

const template = require('./DashboardContractBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Account = require('../../../js/authentication/Account');

// @vue/component
module.exports = {
    bemName: 'dashboard-contract-block',
    constants: {
        SOLUTION_PRO_LINK: 'https://solutionspro.bienici.com/nos-offres/agent-immobilier/',
        IKIMOTECH_LINK: 'https://bienici.ikimotech.com/login',
    },
    mixins: [
        i18nMixin({
            prefix: 'DashboardContractBlock.',
            keys: [
                'title',
                'solutionProLinkText',
            ],
        }),
    ],
    props: {
        account: {
            type: Object,
            required: true,
        },
        enabledAccountOptions: {
            type: Object,
            required: true,
        },
    },
    computed: {
        eligibleAccountOptionsWithoutCanAccessIkimotech() {
            const {enabledAccountOptions} = this;
            return _.pickBy(
                _.omit(enabledAccountOptions, 'canAccessIkimotech'),
                (_, accountOption) => enabledAccountOptions[accountOption]
            );
        },
        canDisplaySolutionProLink() {
            return Account.isAgency(this.account);
        },
        canDisplayOptionCanAccessIkimotech() {
            return _.get(this.enabledAccountOptions, 'canAccessIkimotech');
        },
    },
    methods: {
        translateEnabledAccountOption(optionName, key) {
            return this.t('callToAction.enabledOptions.' + optionName + key);
        },
        translateCanAccessIkimotechOption(key) {
            return this.translateEnabledAccountOption('canAccessIkimotech', '.' + key);
        },
    },
    template: template(),
};
