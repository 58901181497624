const template = require('./LastContactsBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const ContactsResults = require('../../contacts/components/ContactsResults');

// @vue/component
module.exports = {
    constants: {
        SEE_ALL_CONTACTS_LINK: '/mes-contacts',
        MAX_CONTACTS: 3,
    },
    components: {
        ContactsResults,
    },
    mixins: [
        i18nMixin({
            prefix: 'LastContactsBlock.',
            keys: [
                'title',
                {
                    'seeAll': 'seeAllButtonText',
                },
            ],
        }),
    ],
    props: {
        accountId: {
            type: String,
            required: true,
        },
        startDate: {
            type: Object,
            required: true,
        },
        endDate: {
            type: Object,
            required: true,
        },
        loadTable: Boolean,
    },
    data() {
        return {
            collapsedBlock: true,
        };
    },
    methods: {
        onToggle() {
            this.collapsedBlock = !this.collapsedBlock;
            this.$emit('toggle', this.collapsedBlock);
        },
    },
    template: template(),
};
