const _ = require('lodash');
const {formatPhoneToCall} = require('../../utils/PhoneNumberHelper');
const template = require('./ContactUsBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const Account = require('../../authentication/Account');
const {AGENCY_ACCOUNT_TYPE, PROMOTEUR_ACCOUNT_TYPES} = require('../../../common/AccountTypes');

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'ContactUsBlock.',
            keys: [
                'title',
                'byPhone',
                'formattedPhone',
                'availableDays',
                'byMail',
                'agenciesMail',
                'newMail',
                'contactFormBegin',
                'contactFormEnd',
            ],
        }),
    ],
    props: {
        account: {
            type: Object,
            required: true,
        },
    },
    computed: {
        contactUsUrl() {
            return this.getContactUsUrl(this.account);
        },
        isDeveloper() {
            return Account.isDeveloper(this.account);
        },
        phoneToCall() {
            return 'tel:' + formatPhoneToCall(this.formattedPhone);
        },
        emailLink() {
            return `mailto:${this.email}`;
        },
        email() {
            return this.isDeveloper ? this.newMail : this.agenciesMail;
        },
    },
    methods: {
        getContactUsUrl() {
            let contactUsParam = '';
            if (this.account.accountType === AGENCY_ACCOUNT_TYPE) {
                contactUsParam = 'agence';
            } else if (_.includes(PROMOTEUR_ACCOUNT_TYPES, this.account.accountType)) {
                contactUsParam = 'promoteur';
            }
            if (contactUsParam) {
                contactUsParam = '?vous-etes=' + contactUsParam;
            }
            return '/nous-contacter' + contactUsParam;
        },
    },
    template: template(),
};
