const _ = require('lodash');

const RealEstateAdSuggestions = require('./RealEstateAdSuggestions');
const template = require('./RealEstateAdsDiagnosticBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');
const NumberFormatter = require('../../../common/NumberFormatter');

// @vue/component
module.exports = {
    components: {
        RealEstateAdSuggestions,
    },
    mixins: [
        i18nMixin({
            prefix: 'RealEstateAdsDiagnosticBlock.',
            keys: [
                'title',
                'titleDescription',
                'description',
                'whiteBookLabel',
                'whiteBookLinkText',
            ],
        }),
    ],
    constants: {
        WHITE_BOOK_LINK: '/article/livre-blanc-comment-ameliorer-la-performance-de-ses-annonces-immobilieres',
    },
    props: {
        diagnosticConfig: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            collapsedBlock: true,
        };
    },
    computed: {
        config() {
            const config = {...this.diagnosticConfig};
            _.each(config, (value, key) => {
                value.searchUrlTitle = this.translate(value, key, 'title');
                value.blogUrlTitle = this.translate(value, key, 'linkToArticleTitle');
            });
            return config;
        },
    },
    methods: {
        translate(item, name, translationKey) {
            return this.t(`diagnostics.${name}.${translationKey}`, {
                count: item.count,
                formattedCount: NumberFormatter.formatNumber(item.count),
            });
        },
    },
    template: template(),
};
