const _ = require('lodash');
const AgencyInfoBasePage = require('./AgencyInfoBasePage');
const Account = require('../authentication/Account');

module.exports = class MyAgencyInfoPage extends AgencyInfoBasePage {
    constructor() {
        super({
            name: 'myAgencyInfoPage',
            title: 'Ma page publique',
            isRegistrationRequired: true,
        });
    }

    _getAccountIdOrNamespace() {
        return Account.getAuthenticatedAccountId();
    }

    getUrlPattern() {
        return '/ma-page-publique';
    }

    _canOpenPage(options) {
        return _.get(options, 'author.canEnablePublicPage');
    }
};
