const template = require('./PartnerOffersBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

const BASE_URL = '/nos-offres-partenaires';

// @vue/component
module.exports = {
    mixins: [
        i18nMixin({
            prefix: 'PartnerOffersBlock.',
            keys: [
                'title',
                'description',
                'seeAllText',
                'seeAllSmallText',
            ],
        }),
    ],
    props: {
        blogTag: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        offersLink() {
            const {blogTag} = this;
            return blogTag ? `${BASE_URL}/${blogTag}` : BASE_URL;
        },
        blockLink() {
            const {seeAllSmallText: text, offersLink: href} = this;
            return {href, text};
        },
    },
    template: template(),
};
