const _ = require('lodash');
const {addPage} = require('../pages/PageManager');
const Pages = require('../pages/Pages');
const Views = require('../views/Views');
const HeaderProView = require('../header/HeaderProView');
const FooterView = require('../footer/FooterView');
const HomePagePro = require('../pages/HomePagePro');
const RealEstateAdModificationPage = require('../adEditor/RealEstateAdModificationPage');
const RealEstateAdCreationPage = require('../adEditor/RealEstateAdCreationPage');
const AccountManagementPage = require('../pages/AccountManagementPage');
const ArticlesHubPage = require('../blog/hub/ArticlesHubPage');
const LostPasswordPage = require('../pages/LostPasswordPage');
const ResetPasswordPage = require('../pages/ResetPasswordPage');
const MyAccountStatsPage = require('../pages/MyAccountStatsPage');
const AccountStatsPage = require('../pages/AccountStatsPage');
const MyAgencyInfoPage = require('../agencyInfoPage/MyAgencyInfoPage');
const ContactUsPage = require('../pages/ContactUsPage');
const SimpleContentPage = require('../pages/SimpleContentPage');
const ArticlePage = require('../blog/article/ArticlePage');
const SimpleContentPagesPro = require('../pages/SimpleContentPagesPro');
const BlogHelper = require('../blog/BlogHelper');
const BlogPages = require('../blog/BlogPages');
const AccountCreationPage = require('../pages/pro/AccountCreationPage');
const DashboardProPage = require('../pages/pro/DashboardProPage');
const MyAccountContactsPage = require('../pages/pro/MyAccountContactsPage');
const AccountContactsPage = require('../pages/AccountContactsPage');
const MyProspectingMapPage = require('../prospectingMapPage/MyProspectingMapPage');
const MyAdsNotificationBoostsHistoryPage = require('../notificationBoost/MyAdsNotificationBoostsHistoryPage');

module.exports = configureRoutes;

function configureRoutes({
    savedOptions,
    isMobile,
    headerTemplate,
}) {
    const DEFAULT_AUTHENTICATED_PAGE = '/mon-tableau-de-bord';

    const headerProView = new HeaderProView(_.extend({
        headerTemplate,
        headerTitleEnabled: isMobile,
        logoAlwaysEnabled: !isMobile,
        hasToDisplayFavorites: false,
    }, savedOptions));

    const footerView = new FooterView();

    const home = new HomePagePro({
        headerProView,
        pageRedirectionIfAuthenticated: DEFAULT_AUTHENTICATED_PAGE,
        isRegistrationRequired: false,
        footerView,
    });
    addPage(home);

    addPage(new AccountCreationPage({
        pageRedirectionIfAuthenticated: DEFAULT_AUTHENTICATED_PAGE,
    }));

    addPage(new RealEstateAdCreationPage('pro'));

    const adModificationPage = new RealEstateAdModificationPage({
        mode: 'pro',
    });
    addPage(adModificationPage);

    addPage(new AccountManagementPage({
        myAccount: true,
        mode: 'pro',
    }));

    BlogPages.registerPages({
        mode: 'pro',
    });

    addPage(new LostPasswordPage());

    addPage(new ResetPasswordPage());

    const myAccountStatsPage = new MyAccountStatsPage();
    addPage(myAccountStatsPage);

    const accountStatsPage = new AccountStatsPage();
    addPage(accountStatsPage);

    const dashboardProPage = new DashboardProPage();
    addPage(dashboardProPage);

    addPage(new MyAgencyInfoPage());

    addPage(new MyProspectingMapPage());

    const contactUsPage = new ContactUsPage({
        pageContext: 'pro',
    });
    addPage(contactUsPage);

    addPage(new MyAdsNotificationBoostsHistoryPage());

    const contactsPage = new AccountContactsPage();
    addPage(contactsPage);
    addPage(new MyAccountContactsPage());

    _.each(SimpleContentPagesPro, options => {
        if (!options.enabler || options.enabler()) {
            addPage(new SimpleContentPage(options));
        }
    });

    _.extend(Views, {
        header: headerProView,
        footer: footerView,
        articlesHubMenuViews: BlogHelper.createHubMenuViews(),
    });
    _.extend(Pages, {
        home,
        myAccountStatsPage,
        accountStatsPage,
        dashboardProPage,
        contactUs: contactUsPage,
        adModification: adModificationPage,
        ArticlePage,
        ArticlesHubPage,
        contactsPage,
    });
}
