const _ = require('lodash');
const ApplicationPage = require('./ApplicationPage');
const AuthenticationView = require('../authentication/AuthenticationView');

module.exports = class HomePagePro extends ApplicationPage {
    constructor(config) {
        const configuration = _.defaults(config, {
            name: 'home',
            url: '/',
            gtmCategory: 'Homepage',
        });
        super(configuration);
        this.configuration = configuration;
        this.headerProView = configuration.headerProView;
        this.footerView = configuration.footerView;
        this.homeView = new AuthenticationView({origin: 'homePagePro'});
    }

    open(options) {
        super.open(_.extend(options, this.configuration));
    }

    getViews() {
        return [
            this.headerProView,
            this.homeView,
            this.footerView,
        ];
    }

    getUrlPattern() {
        return this.configuration.url;
    }

    getUrl() {
        return '/';
    }
};
