const _ = require('lodash');
const BrowserDetect = require('browser-detect');
const CompositeVueView = require('../../vue/CompositeVueView');
const template = require('../../templates/pro/dashboardPro.jade');
const {insertUserInfoView} = require('../UserInfoView');
const ArticlesListView = require('../../blog/list/ArticlesListView');
const PageManager = require('../../pages/PageManager');
const Pages = require('../../pages/Pages');
const ArticleHelper = require('../../blog/article/ArticleHelper');
const BlogHelper = require('../../blog/BlogHelper');
const EventPack = require('../../utils/EventPack');
const Account = require('../../authentication/Account');
const UrlHelper = require('../../../common/UrlHelper');
const NumberFormatter = require('../../../common/NumberFormatter');
const DateFormatter = require('../../../common/formatters/DateFormatter');
const ServerConfig = require('../../ServerConfig');
const AdvertisementsHelper = require('../../views/utils/AdvertisementsHelper');
const StatsHelper = require('../../stats/StatsHelper');
const LocalBannersStats = require('../../stats/components/LocalBannersStats');
const RealEstateAdSummary = require('../../agency/components/RealEstateAdSummary');
const LastContactsBlock = require('../../pro/components/LastContactsBlock');
const AccountStatsBlock = require('../../pro/components/AccountStatsBlock');
const RealEstateAdsDiagnosticBlock = require('../../pro/components/RealEstateAdsDiagnosticBlock');
const RealEstateAdsPerfSuggestionsBlock = require('../../pro/components/RealEstateAdsPerfSuggestionsBlock');
const PartnerOffersBlock = require('../../pro/components/PartnerOffersBlock');
const AccountBlock = require('../../pro/components/AccountBlock');
const ContactUsBlock = require('../../pro/components/ContactUsBlock');
const {
    DASHBOARD_PRO_MODE,
    DASHBOARD_AND_STATS_PRO_MOBILE_MODE,
} = require('../../stats/components/constants');
const DashboardContractBlock = require('../../pro/components/DashboardContractBlock');
const AdsNotificationBoostsHistoryBlock = require('../../pro/components/AdsNotificationBoostsHistoryBlock');
const HelpBlock = require('../../pro/components/HelpBlock');
const FindProAdsOnThePublicSiteBlock = require('../../pro/components/FindProAdsOnThePublicSiteBlock');

module.exports = class DashboardProView extends CompositeVueView {
    constructor() {
        super({
            template,
        });
        this._eventWhileShown = new EventPack();
    }

    show(options) {
        const {
            startDate,
            endDate,
        } = StatsHelper.getDefaultPeriod();
        const account = options.account;

        const vueData = {
            account,
            enabledAccountOptions: _.extend({
                canHighlightAds: Account.canHighlightAds(),
                canMarkAdsAsLeading: Account.canMarkAdsAsLeading(),
                canAccessIkimotech: _.get(account, 'proOptions.eligibleForIkimoTech'),
            }, _.pick(account, ['canOrderA3dPlan', 'canOrderA3dModel'])),
            realEstateAdSummaryOptions: {},
            loadLastContactsTable: false,
        };
        this.vueData = vueData;

        // @vue/component
        const vueOptions = {
            components: {
                LocalBannersStats,
                DashboardContractBlock,
                AdsNotificationBoostsHistoryBlock,
                HelpBlock,
                RealEstateAdSummary,
                LastContactsBlock,
                AccountStatsBlock,
                RealEstateAdsDiagnosticBlock,
                RealEstateAdsPerfSuggestionsBlock,
                PartnerOffersBlock,
                AccountBlock,
                FindProAdsOnThePublicSiteBlock,
                ContactUsBlock,
            },
            data: vueData,
            computed: {
                accountStatsMode() {
                    return this.isMobile ? DASHBOARD_AND_STATS_PRO_MOBILE_MODE : DASHBOARD_PRO_MODE;
                },
                accountId() {
                    return Account.getAuthenticatedAccountId();
                },
                startDate() {
                    return startDate;
                },
                endDate() {
                    return endDate;
                },
                canDisplayAdsNotificationBoostsHistory() {
                    return Account.canDisplayAdsNotificationBoostsHistory();
                },
                adsDiagnosticConfig() {
                    return options.adDiagnosticConf;
                },
                canDisplayAdsDiagnosticBlock() {
                    return _.size(this.adsDiagnosticConfig) > 0;
                },
                adsPerformanceConfig() {
                    return options.improvableAdsConf;
                },
                canDisplayAdsPerformanceBlock() {
                    return _.size(this.adsPerformanceConfig) > 0;
                },
                blogTag() {
                    return options.blogTag;
                },
            },
            methods: {
                lastContactsBlockToggled() {
                    vueData.loadLastContactsTable = true;
                },
            },
        };
        super.show({
            account,
            Account,
            fromDate: DateFormatter.humanizeDay(startDate),
            toDate: DateFormatter.humanizeDay(endDate),
            slugify: UrlHelper.slugify,
            formatNumber: NumberFormatter.formatNumber,
            showContractBlock: !Account.isNetwork(account) && !Account.hasImport(account),
            comKitFilePath: ServerConfig.config.fileUrl + '/raw/kitcom',
            getAdvertisementData,
        }, vueOptions);
        insertUserInfoView(this.$element.find('.accountInfoHeader'), {
            hideContract: true,
            user: account,
            relatedAccounts: null,
            isAuthor: true,
            hideActionsLinks: true,
            displayMonthlyContactRequestsLimit: false,
        });
        this.$compactArticlesContainer = this.$element.find('.compactArticlesContainer');
        this._addArticlesView({
            articlesList: options.articlesList,
            currentCategory: null,
            $container: this.$compactArticlesContainer,
            blogTag: options.blogTag,
        });
    }

    hide() {
        this._eventWhileShown.removeAllListeners();
        super.hide();
    }

    showUserAdsCount(options) {
        if (this.isShown() && options) {
            const realEstateAdSummaryOptions = _.pick(options, ['author', 'onlyOwnData', 'origin', 'userAdsResume']);
            this.vueData.realEstateAdSummaryOptions = realEstateAdSummaryOptions;
        }
    }

    _addArticlesView(options) {
        this.articlesList = options.articlesList;
        this.articlesListView = new ArticlesListView(options);
        this.articlesListView.setArticlesContainer(this.$compactArticlesContainer);
        this.articlesListView.show();
        this.articlesListView.showArticles({useHTMLHeadingsTags: false});
        this._eventWhileShown.on(this.articlesListView, 'openArticle', _.partialRight(this._openArticle, options.blogTag));
    }

    _openArticle(event, blogTag) {
        const currentElement = event.currentTarget;
        const slug = currentElement.dataset.slug;
        const options = {
            article: ArticleHelper.getArticleFromArticlesList(this.articlesList.articles, slug),
            articlesListView: this.articlesListView,
            category: BlogHelper.getTag(blogTag),
        };
        PageManager.openPage(new Pages.ArticlePage({
            mode: 'pro',
        }), options);
    }
};

function getAdvertisementData(type) {
    return AdvertisementsHelper.getAdsData({
        page: 'dashboardPro',
        type: BrowserDetect.isMobile() ? 'block' : type,
        contract: Account.getAuthenticatedAccount().highestContract,
    });
}
