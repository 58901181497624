const ApplicationPage = require('../ApplicationPage');
const ProAccountCreationView = require('../../authentication/ProAccountCreationView');

module.exports = class AccountCreationPage extends ApplicationPage {
    constructor({pageRedirectionIfAuthenticated}) {
        super({
            name: 'accountCreation',
            title: 'Créer mon compte',
            isRegistrationRequired: false,
            pageRedirectionIfAuthenticated,
        });
        this.accountCreationView = new ProAccountCreationView();
    }

    getMiddleViews() {
        return this.accountCreationView;
    }

    getUrlPattern() {
        return '/creer-mon-compte';
    }
};
