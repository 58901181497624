const template = require('./HelpBlock.jade');
const i18nMixin = require('../../vue/components/mixins/i18n');

const NEED_HELP_LINK = '/besoin-d-aide';
const NEED_HELP_ICON = 'aide-2';

// @vue/component
module.exports = {
    // to avoid conflicts with styles on .help-block declared in fack
    // at bootstrap-stylus/stylus/forms.styl
    bemName: 'pro-help-block',
    mixins: [
        i18nMixin({
            prefix: 'HelpBlock.',
            keys: [
                'title',
                {
                    'faq': 'FAQText',
                },
            ],
        }),
    ],
    computed: {
        blocks() {
            const {FAQText} = this;
            return [
                {
                    name: 'need-help',
                    href: NEED_HELP_LINK,
                    icon: NEED_HELP_ICON,
                    label: FAQText,
                },
            ];
        },
    },
    template: template(),
};
