const _ = require('lodash');
const SimpleContentPagesCommon = require('./SimpleContentPagesCommon');

const proPages = [
    {
        contentPath: 'legal-information-pro',
        title: 'Informations légales',
        bodyClass: 'defaultPageWithNumberedTitles',
        isRegistrationRequired: false,
        gtmCategory: 'Content',
    },
    {
        contentPath: 'about-pro',
        title: 'Découvrez Bien’ici',
        urlPattern: 'decouvrez-bienici',
    },
    {
        contentPath: 'audience-products-landingpage',
        title: 'Solutions d’audience Bien’ici',
        urlPattern: 'produits-audience',
    },
    {
        contentPath: 'terms-of-sales',
        title: 'Conditions générales de vente',
        bodyClass: 'defaultPageWithNumberedTitles termsOfSales',
        isRegistrationRequired: true,
        gtmCategory: 'Content',
    },
];

module.exports = _.union(proPages, SimpleContentPagesCommon);
